code {
  color: #FF8300;
}

.iPhoneWindow {
  border: 1px solid #000;
  height: 667px;
}

.sectionHeader {
  background-color: #D71D4A;
  color: #fff;
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styleguideIconBucket {
  margin-right: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  width: 15%;
}

.styleguideIconBucket i {
  font-size: 2rem;
}

.styleguideIconLabel {
  white-space: nowrap;
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  color: #FF8300;
  font-size: 0.8rem;
}

/*
.styleguideHeadingH1:before {
  content: var(--h1) " rem";
  display: inline;
}
*/
